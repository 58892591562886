<template>
  <Container
    :preloader="preloader"
    class="container"
  >
    <template #content>
      <form @submit.prevent="submit()">
        <h2>
          {{ $t('views.auth.sign-up-phone-verify.title') }}
        </h2>

        <p>
          {{ $t('views.auth.sign-up-phone-verify.text') }} +{{ value.phone }}
        </p>

        <InputText
          :placeholder="`${$t('views.auth.sign-up-phone-verify.input')}*`"
          :errors="errors.code"
          :reg-exp="/\D/g"
          v-model="form.code"
        />

        <BlueButton
          :text="$t('views.auth.sign-up-phone-verify.send-code')"
          :loading="loading"
          type="submit"
          :disabled="loading || (seconds > 0 && form.code.length === 0)"
        />

        <BlueText
          style="margin: 10px 0 0;"
          tag="RouterLink"
          :text="$t('views.auth.sign-up-phone-verify.link')"
          :to="{ name: 'SignUpVerifyPhoneCodeResend' }"
        />
      </form>
    </template>
  </Container>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import InputText from '@/ui/inputs/Text.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import BlueText from '@/ui/buttons/BlueText.vue'

const app = getCurrentInstance()
const { appContext: { config: { globalProperties: { $axios, $auth } } } } = app
const $route = useRoute()
const $router = useRouter()

const preloader = ref(true)
const loading = ref(false)
const value = ref({})
const form = ref({
  code: ''
})

const seconds = ref(0)

const errors = ref({
  code: []
})

async function fetch () {
  try {
    preloader.value = true

    const { data } = await $axios.get(`auth/get-phone-by-hash/${$route.params.hash}`)
    value.value = data
  } catch (err) {
    $router.push({ name: 'SignIn' })
    throw new Error(err)
  } finally {
    preloader.value = false
  }
}

async function submit () {
  try {
    loading.value = true

    const { status, data } = await $axios.post(
      `auth/verify-sms-by-hash/${$route.params.hash}`, {
        code: form.value.code
      })

    if (status === 200) {
      $auth.redirect({
        token: data.access_token,
        isRefresh: true
      })
    }
  } catch (err) {
    errors.value.code = []
    errors.value.code.push('errors.code')

    throw new Error(err)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetch()
})
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 32px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    p {
      color: rgba(156, 163, 184, 1);
      font-family: Medium;
      font-size: 14px;
      line-height: 100%;
    }
  }
}
</style>
